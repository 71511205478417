@charset "UTF-8";

#login-screen {
    background: #112542;
    overflow: hidden;

    .login-layer {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding-top: 100px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 470px;
            height: 345px;
            background: url("#{$images_dir}login-layer-1.svg") no-repeat center center;
            background-size: cover;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 683px;
            height: 604px;
            background: url("#{$images_dir}login-layer-2.svg") no-repeat center center;
            background-size: cover;
        }
    }

    .login-logo {
        display: block;
        width: 116px;
        height: 87.8px;
        margin-bottom: 80px;
        position: relative;
        z-index: 2;
    }

    .login-title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        color: $base-color2;
        margin-bottom: 80px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
            width: 86px;
            height: 2px;
            background: $base-color4;
        }
    }

    .login-list{
        margin: 16px 0;

        li a{
            display: block;
            margin-bottom: 5px;
            color: $base-color4;
        }
    }

    .login-form {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 630px;
            height: 630px;
            left: 50%;
            top: 55%;
            transform: translate(-50%, -50%);
            background: linear-gradient(180deg, #23BEE2 0%, #112542 100%);
            filter: blur(150px);
        }

        .form-row {
            position: relative;
            overflow: hidden;
            width: 100%;

            &.email {

                &:before {
                    content: "";
                    position: absolute;
                    top: 12px;
                    left: 15px;
                    width: 14px;
                    height: 14px;
                    background: url("#{$images_dir}login-mail.svg") no-repeat center center;
                    background-size: cover;
                }
            }

            #pwd-reveal {
                display: block;
                position: absolute;
                top: 12px;
                right: 15px;
                width: 18px;
                height: 18px;
                background: url("#{$images_dir}login-pwd.svg") no-repeat center center;
                background-size: cover;
                cursor: pointer;
            }
        }

        label {
            position: absolute;
            left: -100%;
        }

        input {
            width: 100%;
            height: 40px;
            border: 1px solid #D9D9D9;
            border-radius: 5px;
            margin-bottom: 20px;
            font-family: 'Roboto', sans-serif;
        }

        input[type="email"] {
            padding: 2px 40px;
        }

        input[type="password"],
        input[type="text"]{
            padding: 2px 40px 2px 15px;
        }

        button {
            width: 100%;
            height: 46px;
            background: $base-color4;
            border: 1px solid $base-color4;
            color: #ffffff;
            border-radius: 5px;
            margin-top: 20px;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            cursor: pointer;
        }
    }

    .form-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        background: #ffffff;
        padding: 60px;
        border-radius: 26px;
    }
}

.ea {

    body {
        background-color: #080710;
    }

    .background {
        height: 100vh;
        width: 100vw;
        background: url("#{$images_dir}intro-bkg.jpg") no-repeat center center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: fixed;
        z-index: 10;
    }

    .actions {
        color: $base-color1;
    }

    form {
        height: 520px;
        width: 400px;
        background-color: $base-color3;
        border-radius: 10px;
        box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
        padding: 50px 35px;
    }

    form {
        color: #ffffff;
        letter-spacing: 0.5px;
        outline: none;
        border: none;
    }

    label {
        display: block;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
    }

    input {
        display: block;
        height: 50px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.07);
        border-radius: 3px;
        padding: 0 10px;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 300;
        color: $base-color1
    }

    ::placeholder {
        color: $base-color1;
    }

    button {
        margin-top: 50px;
        width: 100%;
        background-color: #ffffff;
        color: $base-color3;
        padding: 15px 0;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        cursor: pointer;
    }
}