@charset "UTF-8";

#landing-screen{
  height: 100vh;
  width: 100vw;
  background: url("#{$images_dir}intro-bkg.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  z-index: 10;

  &.go-next{
    display: none;
  }

  .landing-content{
    background: $base-color3;
    border-radius: 45px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.60);
    max-width: 750px;
    width: 100%;

   .top{
     padding: 20px 16px;
     text-align: center;

     .landing-title{
       color: $base-color4;
       margin-bottom: 14px;
       font-size: 24px;
       font-weight: 400;
       line-height: 28px;

       span{
        display: block;
         font-size: 32px;
         font-weight: 700;
         line-height: 36px;
         color: $base-color1;
         margin: 16px auto 0;
       }
     }
   }

    .middle{
      height: 300px;
      background: url("#{$images_dir}intro-img.jpg") no-repeat center center;
      background-size: cover;
    }

    .bottom{
      padding: 20px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p{
        max-width: 491px;
        width: 100%;
        margin: 0 auto 30px;
        color: $base-color1;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: $mobile-landscape+1px) {

  #landing-screen{

    .landing-content{
      border-radius: 0;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .top{
        padding: 16px;

        .landing-title{
          margin-bottom: 10px;
          font-size: 20px;
          line-height: 24px;

          span{
            font-size: 28px;
            line-height: 32px;
          }
        }
      }

      .middle{
        height: 200px;
        width: 100%;
      }

      .bottom{
        padding: 16px;

        p{
          text-align: center;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
}