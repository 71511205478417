@charset "UTF-8";

#game-screen {
  height: 100vh;
  display: block;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  .app-grid {
    display: grid;
    grid-template-columns: 1fr 430px;
    height: calc(100vh - 116px);
  }

  .game-content {
    background: $base-color2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &.no-cursor {
      pointer-events: none;
    }

    .max-box {
      max-width: 1152px;
      width: 100%;
      margin: 0 auto;
    }

    #radio-container {
      width: 100%;
      height: auto;
      aspect-ratio: 1.42;
      //padding-bottom: 75%;
      background: lightgray;
      position: relative;
      overflow: hidden;

      &.carry {
        cursor: url("#{$images_dir}cursor-pink.svg") 33 33,
        auto;
      }

      &.calculus {
        cursor: url("#{$images_dir}cursor-green.svg") 33 33,
        auto;
      }

      &.margin {
        cursor: url("#{$images_dir}cursor-purple.svg") 33 33,
        auto;
      }

      &.radio {
        cursor: url("#{$images_dir}cursor-blue.svg") 33 33,
        auto;
      }

      #game-canvas {
        position: absolute;
        background: $base-color2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: grid;
      }

      .radio-step {
        position: relative;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      svg {
        //position: absolute;
        //z-index: 1;
        //top: 0;
        //left: 0;
        //width: 100%;
        //height: 100%;
        //background: $base-color2;

          rect {
            //display: none;

            &:nth-child(1) {
              display: block;
            }
          }
      }

      .is-ai-miss{
        opacity: 0;
      }

      .is-first-carie,
      .is-second-carie,
      .is-third-carie,
      .is-fourth-carie,
      .is-fifth-carie,
      .is-sixth-carie,
      .is-seventh-carie,
      .is-eighth-carie,
      .is-ninth-carie,
      .is-tenth-carie,
      .is-eleventh-carie,
      .is-twelfth-carie,
      .is-thirteenth-carie,
      .is-fourteenth-carie,
      .is-fifteenth-carie{
        //fill: red;
        //fill-opacity: 0.3;
        opacity: 0;
      }

      .is-first-calculus,
      .is-second-calculus,
      .is-third-calculus,
      .is-fourth-calculus,
      .is-fifth-calculus,
      .is-sixth-calculus,
      .is-seventh-calculus,
      .is-eighth-calculus,
      .is-ninth-calculus,
      .is-tenth-calculus,
      .is-eleventh-calculus,
      .is-twelfth-calculus {
        //fill: chartreuse;
        //fill-opacity: 0.3;
        opacity: 0;
      }

      .is-first-margin,
      .is-second-margin,
      .is-third-margin,
      .is-fourth-margin {
        //fill: purple;
        //fill-opacity: 0;
        opacity: 0;
      }

      .is-first-radio,
      .is-second-radio,
      .is-third-radio {
        //fill: darkblue;
        //fill-opacity: 0.3;
        opacity:0;
      }

      .second-radio {
        display: none;

        svg {
          display: none;
        }

        &.active {
          display: block;

          svg {
            display: block;
            width: 100%;
            height: 100%;
          }

          &.done {
            display: none;

            svg {
              display: none;
            }
          }
        }
      }

      .third-radio {
        display: none;

        &.active {
          display: block;

          &.done {
            display: none;
          }
        }
      }

      .last-radio {
        //background: purple;
        background-size: cover;
        display: none;

        &.active {
          display: block;
        }
      }
    }

    .circle-template {
      position: absolute;
      left: -200%;
      width: 40px;
      height: 40px;
    }

    .overlayDiv {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      z-index: 14;

      //NEW
      &:hover{

        .remove-circle{
          display: block;
          animation: opacityAnimation 650ms;
        }
      }

      &.hide {
        display: none;
      }

      .circle-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }

      &:hover,
      &.show-infos {

        .info-text {
          //opacity: 1;
          display: block;
          animation: opacityAnimation 650ms;
          //transition: opacity 650ms ease;
        }
      }

      &.is-carie {
        border: 2px solid $base-color5;

        .circle-center {
          background: $base-color5;
        }

        &.glow {
          box-shadow: inset 0 0 30px 0 $base-color5;
          transition: box-shadow 500ms ease;
        }
      }

      &.calculus {
        border: 2px solid $base-color6;

        .circle-center {
          background: $base-color6;
        }

        &.glow {
          box-shadow: inset 0 0 30px 0 $base-color6;
          transition: box-shadow 500ms ease;
        }
      }

      &.margin {
        border: 2px solid $base-color7;

        .circle-center {
          background: $base-color7;
        }

        &.glow {
          box-shadow: inset 0 0 30px 0 $base-color7;
          transition: box-shadow 500ms ease;
        }
      }

      &.radio {
        border: 2px solid $base-color8;

        .circle-center {
          background: $base-color8;
        }

        &.glow {
          box-shadow: inset 0 0 30px 0 $base-color8;
          transition: box-shadow 500ms ease;
        }
      }

      .remove-circle {
        appearance: none;
        cursor: pointer;
        padding: 0;
        border: none;
        position: absolute;
        z-index: 15;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -5px;
        left: -10px;
        background: url("#{$images_dir}remove-circle.svg") no-repeat center center;
        background-size: cover;
        //NEW
        display: none;
      }

      .info-text {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: $base-color1;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        padding: 12px 14px 11px;
        border-radius: 12px;
        width: max-content;
        display: none;

        &.carie {
          background: $base-color5;
        }

        &.calculus {
          background: $base-color6;
        }

        &.margin {
          background: $base-color7;
        }

        &.radio {
          background: $base-color8;
        }
      }
    }
  }

  .datas-loader {
    position: fixed;
    z-index: 14;
    padding: 0 16px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.loading {
      display: flex;
      animation: opacityAnimation 750ms;
    }

    .loader-content {
      background: $base-color2;
      padding: 20px;
      max-width: 550px;
      width: 100%;
      text-align: center;
      position: relative;
      border-radius: 25px;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        background: $base-color8;
        animation: loaderAnimation 2000ms infinite;
      }

      span {
        position: relative;
        z-index: 2;
        font-size: 20px;
        color: $base-color1;
        font-weight: 700;
      }
    }
  }
}

@media only screen and (max-width: $tablet-landscape+1px) {

  #game-screen {

    .app-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media only screen and (max-width: $tablet-portrait+1px) {

  #game-screen {

    .game-content {

      //#radio-container {
      //
      //  &.carry {
      //    cursor: url("#{$images_dir}cursor-pink.svg") 30 30,
      //    auto;
      //  }
      //
      //  &.calculus {
      //    cursor: url("#{$images_dir}cursor-green.svg") 30 30,
      //    auto;
      //  }
      //
      //  &.margin {
      //    cursor: url("#{$images_dir}cursor-purple.svg") 30 30,
      //    auto;
      //  }
      //
      //  &.radio {
      //    cursor: url("#{$images_dir}cursor-blue.svg") 30 30,
      //    auto;
      //  }
      //}

      //.circle-template {
      //  width: 40px;
      //  height: 40px;
      //}

      //.overlayDiv {
      //  width: 40px;
      //  height: 40px;
      //
      //  .remove-circle {
      //    width: 16px;
      //    height: 16px;
      //    right: -10px;
      //    top: -3px;
      //  }
      //}
    }

    .datas-loader {

      .loader-content {
        padding: 10px;

        span {
          font-size: 16px;
        }
      }
    }
  }
}