@charset "UTF-8";

.aside {
  padding: 42px 36px 37px;
  background: linear-gradient(16deg, #252D42 -14.76%, #263465 96.36%);

  .create {

    &.hide {
      display: none;
    }
  }

  .result {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 25px;

    &.show {
      display: flex;
    }

    .top-title {
      display: block;
      text-align: center;
      color: $base-color1;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 134.523%; /* 32.285px */
      margin-bottom: 20px;
    }

    h2 {
      color: $base-color4;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 134.523%;
      margin-bottom: 31px;
      text-align: center;
      padding: 0 12px;

      span {
        color: $base-color1;
      }
    }

    h3 {
      color: $base-color1;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 26.4px */
    }

    .send-result {
      max-width: 323px;
      width: 100%;
      margin: 20px auto 0;
      background: #3A4561;
      border-radius: 15px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      padding: 25px 15px 26px;
    }

    .hbspt-form {

      .hs-form-field {

        label {
          margin-bottom: 14px !important;
        }
      }
    }
  }

  .side-title {
    position: relative;
    font-size: 20px;
    color: $base-color1;
    font-weight: 700;
    line-height: 112.023%; /* 22.405px */
    margin-bottom: 31px;
    padding-left: 43px;

    span {
      font-size: 20px;
      color: $base-color1;
      font-weight: 700;
      line-height: 112.023%; /* 22.405px */
      padding: 7px 13px;
      background: $base-color3;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .pathologies {
    margin-bottom: 8px;

    li {
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .button-overlay {
      padding: 4px;
      border-radius: 15px;
      border: 0.5px solid rgba(255, 255, 255, 0.15);
      //position: relative;

      &.s-carie {
        border: 0.5px solid $base-color5;
        transition: border 650ms ease;
      }

      &.s-calculus {
        border: 0.5px solid $base-color6;
        transition: border 650ms ease;
      }

      &.s-margin {
        border: 0.5px solid $base-color7;
        transition: border 650ms ease;
      }

      &.s-radio {
        border: 0.5px solid $base-color8;
        transition: border 650ms ease;
      }
    }

    button {
      appearance: none;
      border: none;
      cursor: pointer;
      padding: 12px 13px 11px;
      border-radius: 12px;
      width: 100%;
      text-align: left;

      &.carry {
        background: $base-color5;
      }

      &.calculus {
        background: $base-color6;
      }

      &.margin {
        background: $base-color7;
      }

      &.radio {
        background: $base-color8;
      }

      span {
        color: $base-color1;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 112.023%; /* 20.164px */
        padding-left: 30px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 25px;
          height: 25px;
          background: url("#{$images_dir}add.svg") no-repeat center center;
          background-size: cover;
        }
      }
    }
  }

  #pathologies-list {
    margin-bottom: 14px;
    background: #3A4561;
    padding: 8px 13px 10px 9px;
    border: 1px solid rgba(144, 144, 144, .2);
    border-radius: 17px;
    opacity: 0;

    &.has-item {
      opacity: 1;
      transition: opacity 650ms ease;
    }

    .list-item {
      margin-bottom: 3px;
      padding: 20px 7px 14px 6px;
      background: rgba(99, 111, 140, .2);
      border-radius: 5px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        width: 4px;
        height: 38px;
        border-radius: 28px;
      }

      &.carry {

        &:before {
          background: $base-color5;
        }
      }

      &.calculus {

        &:before {
          background: $base-color6;
        }
      }

      &.margin {

        &:before {
          background: $base-color7;
        }
      }

      &.radio {

        &:before {
          background: $base-color8;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-size: 16px;
        color: $base-color1;
        font-weight: 300;
        line-height: 112.023%; /* 17.924px */
        padding-left: 42px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
          background: url("#{$images_dir}tooth.svg") no-repeat center center;
          background-size: cover;
        }
      }

      .remove {
        appearance: none;
        cursor: pointer;
        padding: 0;
        border: none;
        position: absolute;
        z-index: 2;
        width: 16px;
        height: 16px;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        background: url("#{$images_dir}remove.svg") no-repeat center center;
        background-size: cover;
      }
    }
  }
}

@media only screen and (max-width: $tablet-portrait+1px) {

  .aside{
    padding: 30px 16px;

    .side-title{
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 20px;
    }

    .pathologies{

      button{

        span{
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}