/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, textarea {
  box-sizing: border-box;
}

/* BREAKPOINTS */
/* COLORS */
body {
  position: relative;
  background: #000000;
  font-family: "Roboto", sans-serif;
}

@-webkit-keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes loaderAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.centering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inside {
  position: relative;
  padding: 0 100px;
}

img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.text {
  font-weight: 300;
  line-height: 152.523%; /* 24.404px */
}
.text.white {
  color: #F4F0EB;
}
.text.margin-btm {
  margin-bottom: 20px;
}
.text strong {
  font-weight: bold;
}

.button {
  appearance: none;
  border: none;
  cursor: pointer;
  border-radius: 66px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}
.button.basic {
  padding: 14px 30px 13px;
}
.button.small {
  font-size: 16px;
  padding: 14px 30px 13px;
}
.button.arrow {
  padding: 14px 50px 13px 30px;
  position: relative;
}
.button.arrow:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  width: 28.5px;
  height: 27.5px;
  background: url("../../../public/images/arrow.svg") no-repeat center center;
  background-size: cover;
  transition: all 650ms ease;
}
.button.arrow:hover:before {
  right: 10px;
  background: url("../../../public/images/arrow-blue.svg") no-repeat center center;
  background-size: cover;
}
.button.blue {
  color: #F4F0EB;
  background: #40A9FF;
  border: 1px solid #40A9FF;
}
.button.blue:hover {
  color: #40A9FF;
  background: #F4F0EB;
  transition: all 650ms ease;
}
.button.white {
  color: #40A9FF;
  background: #F4F0EB;
  border: 1px solid #F4F0EB;
}
.button.white:hover {
  color: #F4F0EB;
  background: transparent;
  transition: all 650ms ease;
}

.hbspt-form .hs-submit.locked {
  cursor: not-allowed;
}
.hbspt-form .hs-submit.locked .hs-button {
  opacity: 0.5;
  pointer-events: none;
}
.hbspt-form .hs-form-field {
  margin-bottom: 14px;
}
.hbspt-form .hs-form-field label {
  display: block;
  color: #F4F0EB !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: 134.523%; /* 21.524px */
}
.hbspt-form .hs-form-field label.hs-error-msg {
  font-size: 12px;
  font-weight: normal;
  color: red !important;
}
.hbspt-form .hs-form-field .hs-input {
  appearance: none;
  cursor: pointer;
  border: none;
  padding: 5px 20px;
  width: 100%;
  height: 40px;
  background: #D9D9D9;
  border-radius: 5px;
}
.hbspt-form .hs-country,
.hbspt-form .hs-imaging_software_new {
  position: relative;
}
.hbspt-form .hs-country:before,
.hbspt-form .hs-imaging_software_new:before {
  content: "";
  position: absolute;
  top: 45px;
  right: 20px;
  width: 10px;
  height: 10px;
  background: url("../../../public/images/dropdown.png") no-repeat center center;
  background-size: cover;
}
.hbspt-form .submitted-message {
  text-align: center;
  color: #F4F0EB;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
}
.hbspt-form .actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hbspt-form .actions .hs-button {
  appearance: none;
  cursor: pointer;
  border-radius: 66px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  padding: 14px 14px 13px;
  color: #F4F0EB;
  background: #40A9FF;
  border: 1px solid #40A9FF;
  margin-top: 22px;
}
.hbspt-form .actions .hs-button:hover {
  color: #40A9FF;
  background: #F4F0EB;
  transition: all 650ms ease;
}

@media only screen and (max-width: 769px) {
  .button {
    font-size: 16px;
    line-height: 20px;
  }
  .button.small {
    font-size: 14px;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

#header {
  background: #2D3F7B;
  padding: 20px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#header #logo {
  display: block;
  width: 116px;
  height: 87.8px;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}
#header .cta-container {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}
#header .cta-container.remove {
  display: none;
}
#header .cta-container #first-radio.inactive,
#header .cta-container #second-radio.inactive,
#header .cta-container #third-radio.inactive {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
#header .cta-container #first-radio.step-done {
  display: none;
}
#header .cta-container #second-radio {
  display: none;
}
#header .cta-container #second-radio.step-to-do {
  display: block;
}
#header .cta-container #second-radio.step-to-do.step-done {
  display: none;
}
#header .cta-container #third-radio {
  display: none;
}
#header .cta-container #third-radio.step-to-submit {
  display: block;
}
#header .ray-title {
  color: #F4F0EB;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: center;
}
#header .breadcrumps {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}
#header .marker {
  display: flex;
  width: 100%;
  height: 16px;
  border-radius: 55px;
  background: #1A2031;
  position: relative;
}
#header .marker:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 33.33%;
  height: 16px;
  background: #40A9FF;
  border-radius: 55px;
  transition: width 650ms ease;
}
#header .marker.second:before {
  width: 66.66%;
}
#header .marker.second.end:before {
  width: 100%;
}

@media only screen and (max-width: 1025px) {
  #header .cta-container {
    top: 16px;
    transform: none;
  }
}
@media only screen and (max-width: 769px) {
  #header {
    padding: 30px 16px 80px;
  }
  #header #logo {
    top: 0;
    left: 0;
    transform: none;
  }
  #header .ray-title {
    font-size: 30px;
    line-height: 34px;
    text-align: right;
  }
  #header .cta-container {
    top: auto;
    bottom: 16px;
  }
}
#landing-screen {
  height: 100vh;
  width: 100vw;
  background: url("../../../public/images/intro-bkg.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  z-index: 10;
}
#landing-screen.go-next {
  display: none;
}
#landing-screen .landing-content {
  background: #2D3F7B;
  border-radius: 45px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.6);
  max-width: 750px;
  width: 100%;
}
#landing-screen .landing-content .top {
  padding: 20px 16px;
  text-align: center;
}
#landing-screen .landing-content .top .landing-title {
  color: #40A9FF;
  margin-bottom: 14px;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}
#landing-screen .landing-content .top .landing-title span {
  display: block;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  color: #F4F0EB;
  margin: 16px auto 0;
}
#landing-screen .landing-content .middle {
  height: 300px;
  background: url("../../../public/images/intro-img.jpg") no-repeat center center;
  background-size: cover;
}
#landing-screen .landing-content .bottom {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#landing-screen .landing-content .bottom p {
  max-width: 491px;
  width: 100%;
  margin: 0 auto 30px;
  color: #F4F0EB;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

@media only screen and (max-width: 641px) {
  #landing-screen .landing-content {
    border-radius: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #landing-screen .landing-content .top {
    padding: 16px;
  }
  #landing-screen .landing-content .top .landing-title {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
  }
  #landing-screen .landing-content .top .landing-title span {
    font-size: 28px;
    line-height: 32px;
  }
  #landing-screen .landing-content .middle {
    height: 200px;
    width: 100%;
  }
  #landing-screen .landing-content .bottom {
    padding: 16px;
  }
  #landing-screen .landing-content .bottom p {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
  }
}
#game-screen {
  height: 100vh;
  display: block;
  opacity: 0;
}
#game-screen.show {
  opacity: 1;
}
#game-screen .app-grid {
  display: grid;
  grid-template-columns: 1fr 430px;
  height: calc(100vh - 116px);
}
#game-screen .game-content {
  background: #000000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
#game-screen .game-content.no-cursor {
  pointer-events: none;
}
#game-screen .game-content .max-box {
  max-width: 1152px;
  width: 100%;
  margin: 0 auto;
}
#game-screen .game-content #radio-container {
  width: 100%;
  height: auto;
  aspect-ratio: 1.42;
  background: lightgray;
  position: relative;
  overflow: hidden;
}
#game-screen .game-content #radio-container.carry {
  cursor: url("../../../public/images/cursor-pink.svg") 33 33, auto;
}
#game-screen .game-content #radio-container.calculus {
  cursor: url("../../../public/images/cursor-green.svg") 33 33, auto;
}
#game-screen .game-content #radio-container.margin {
  cursor: url("../../../public/images/cursor-purple.svg") 33 33, auto;
}
#game-screen .game-content #radio-container.radio {
  cursor: url("../../../public/images/cursor-blue.svg") 33 33, auto;
}
#game-screen .game-content #radio-container #game-canvas {
  position: absolute;
  background: #000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
}
#game-screen .game-content #radio-container .radio-step {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#game-screen .game-content #radio-container svg rect:nth-child(1) {
  display: block;
}
#game-screen .game-content #radio-container .is-ai-miss {
  opacity: 0;
}
#game-screen .game-content #radio-container .is-first-carie,
#game-screen .game-content #radio-container .is-second-carie,
#game-screen .game-content #radio-container .is-third-carie,
#game-screen .game-content #radio-container .is-fourth-carie,
#game-screen .game-content #radio-container .is-fifth-carie,
#game-screen .game-content #radio-container .is-sixth-carie,
#game-screen .game-content #radio-container .is-seventh-carie,
#game-screen .game-content #radio-container .is-eighth-carie,
#game-screen .game-content #radio-container .is-ninth-carie,
#game-screen .game-content #radio-container .is-tenth-carie,
#game-screen .game-content #radio-container .is-eleventh-carie,
#game-screen .game-content #radio-container .is-twelfth-carie,
#game-screen .game-content #radio-container .is-thirteenth-carie,
#game-screen .game-content #radio-container .is-fourteenth-carie,
#game-screen .game-content #radio-container .is-fifteenth-carie {
  opacity: 0;
}
#game-screen .game-content #radio-container .is-first-calculus,
#game-screen .game-content #radio-container .is-second-calculus,
#game-screen .game-content #radio-container .is-third-calculus,
#game-screen .game-content #radio-container .is-fourth-calculus,
#game-screen .game-content #radio-container .is-fifth-calculus,
#game-screen .game-content #radio-container .is-sixth-calculus,
#game-screen .game-content #radio-container .is-seventh-calculus,
#game-screen .game-content #radio-container .is-eighth-calculus,
#game-screen .game-content #radio-container .is-ninth-calculus,
#game-screen .game-content #radio-container .is-tenth-calculus,
#game-screen .game-content #radio-container .is-eleventh-calculus,
#game-screen .game-content #radio-container .is-twelfth-calculus {
  opacity: 0;
}
#game-screen .game-content #radio-container .is-first-margin,
#game-screen .game-content #radio-container .is-second-margin,
#game-screen .game-content #radio-container .is-third-margin,
#game-screen .game-content #radio-container .is-fourth-margin {
  opacity: 0;
}
#game-screen .game-content #radio-container .is-first-radio,
#game-screen .game-content #radio-container .is-second-radio,
#game-screen .game-content #radio-container .is-third-radio {
  opacity: 0;
}
#game-screen .game-content #radio-container .second-radio {
  display: none;
}
#game-screen .game-content #radio-container .second-radio svg {
  display: none;
}
#game-screen .game-content #radio-container .second-radio.active {
  display: block;
}
#game-screen .game-content #radio-container .second-radio.active svg {
  display: block;
  width: 100%;
  height: 100%;
}
#game-screen .game-content #radio-container .second-radio.active.done {
  display: none;
}
#game-screen .game-content #radio-container .second-radio.active.done svg {
  display: none;
}
#game-screen .game-content #radio-container .third-radio {
  display: none;
}
#game-screen .game-content #radio-container .third-radio.active {
  display: block;
}
#game-screen .game-content #radio-container .third-radio.active.done {
  display: none;
}
#game-screen .game-content #radio-container .last-radio {
  background-size: cover;
  display: none;
}
#game-screen .game-content #radio-container .last-radio.active {
  display: block;
}
#game-screen .game-content .circle-template {
  position: absolute;
  left: -200%;
  width: 40px;
  height: 40px;
}
#game-screen .game-content .overlayDiv {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  z-index: 14;
}
#game-screen .game-content .overlayDiv:hover .remove-circle {
  display: block;
  animation: opacityAnimation 650ms;
}
#game-screen .game-content .overlayDiv.hide {
  display: none;
}
#game-screen .game-content .overlayDiv .circle-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
#game-screen .game-content .overlayDiv:hover .info-text, #game-screen .game-content .overlayDiv.show-infos .info-text {
  display: block;
  animation: opacityAnimation 650ms;
}
#game-screen .game-content .overlayDiv.is-carie {
  border: 2px solid #FF40A7;
}
#game-screen .game-content .overlayDiv.is-carie .circle-center {
  background: #FF40A7;
}
#game-screen .game-content .overlayDiv.is-carie.glow {
  box-shadow: inset 0 0 30px 0 #FF40A7;
  transition: box-shadow 500ms ease;
}
#game-screen .game-content .overlayDiv.calculus {
  border: 2px solid #5DDFA6;
}
#game-screen .game-content .overlayDiv.calculus .circle-center {
  background: #5DDFA6;
}
#game-screen .game-content .overlayDiv.calculus.glow {
  box-shadow: inset 0 0 30px 0 #5DDFA6;
  transition: box-shadow 500ms ease;
}
#game-screen .game-content .overlayDiv.margin {
  border: 2px solid #7C67F5;
}
#game-screen .game-content .overlayDiv.margin .circle-center {
  background: #7C67F5;
}
#game-screen .game-content .overlayDiv.margin.glow {
  box-shadow: inset 0 0 30px 0 #7C67F5;
  transition: box-shadow 500ms ease;
}
#game-screen .game-content .overlayDiv.radio {
  border: 2px solid #1E98FC;
}
#game-screen .game-content .overlayDiv.radio .circle-center {
  background: #1E98FC;
}
#game-screen .game-content .overlayDiv.radio.glow {
  box-shadow: inset 0 0 30px 0 #1E98FC;
  transition: box-shadow 500ms ease;
}
#game-screen .game-content .overlayDiv .remove-circle {
  appearance: none;
  cursor: pointer;
  padding: 0;
  border: none;
  position: absolute;
  z-index: 15;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -5px;
  left: -10px;
  background: url("../../../public/images/remove-circle.svg") no-repeat center center;
  background-size: cover;
  display: none;
}
#game-screen .game-content .overlayDiv .info-text {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: #F4F0EB;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 12px 14px 11px;
  border-radius: 12px;
  width: max-content;
  display: none;
}
#game-screen .game-content .overlayDiv .info-text.carie {
  background: #FF40A7;
}
#game-screen .game-content .overlayDiv .info-text.calculus {
  background: #5DDFA6;
}
#game-screen .game-content .overlayDiv .info-text.margin {
  background: #7C67F5;
}
#game-screen .game-content .overlayDiv .info-text.radio {
  background: #1E98FC;
}
#game-screen .datas-loader {
  position: fixed;
  z-index: 14;
  padding: 0 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#game-screen .datas-loader.loading {
  display: flex;
  animation: opacityAnimation 750ms;
}
#game-screen .datas-loader .loader-content {
  background: #000000;
  padding: 20px;
  max-width: 550px;
  width: 100%;
  text-align: center;
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}
#game-screen .datas-loader .loader-content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: #1E98FC;
  animation: loaderAnimation 2000ms infinite;
}
#game-screen .datas-loader .loader-content span {
  position: relative;
  z-index: 2;
  font-size: 20px;
  color: #F4F0EB;
  font-weight: 700;
}

@media only screen and (max-width: 1025px) {
  #game-screen .app-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 769px) {
  #game-screen .datas-loader .loader-content {
    padding: 10px;
  }
  #game-screen .datas-loader .loader-content span {
    font-size: 16px;
  }
}
.aside {
  padding: 42px 36px 37px;
  background: linear-gradient(16deg, #252D42 -14.76%, #263465 96.36%);
}
.aside .create.hide {
  display: none;
}
.aside .result {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 25px;
}
.aside .result.show {
  display: flex;
}
.aside .result .top-title {
  display: block;
  text-align: center;
  color: #F4F0EB;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.523%; /* 32.285px */
  margin-bottom: 20px;
}
.aside .result h2 {
  color: #40A9FF;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.523%;
  margin-bottom: 31px;
  text-align: center;
  padding: 0 12px;
}
.aside .result h2 span {
  color: #F4F0EB;
}
.aside .result h3 {
  color: #F4F0EB;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 26.4px */
}
.aside .result .send-result {
  max-width: 323px;
  width: 100%;
  margin: 20px auto 0;
  background: #3A4561;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 25px 15px 26px;
}
.aside .result .hbspt-form .hs-form-field label {
  margin-bottom: 14px !important;
}
.aside .side-title {
  position: relative;
  font-size: 20px;
  color: #F4F0EB;
  font-weight: 700;
  line-height: 112.023%; /* 22.405px */
  margin-bottom: 31px;
  padding-left: 43px;
}
.aside .side-title span {
  font-size: 20px;
  color: #F4F0EB;
  font-weight: 700;
  line-height: 112.023%; /* 22.405px */
  padding: 7px 13px;
  background: #2D3F7B;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.aside .pathologies {
  margin-bottom: 8px;
}
.aside .pathologies li {
  margin-bottom: 3px;
}
.aside .pathologies li:last-child {
  margin-bottom: 0;
}
.aside .pathologies .button-overlay {
  padding: 4px;
  border-radius: 15px;
  border: 0.5px solid rgba(255, 255, 255, 0.15);
}
.aside .pathologies .button-overlay.s-carie {
  border: 0.5px solid #FF40A7;
  transition: border 650ms ease;
}
.aside .pathologies .button-overlay.s-calculus {
  border: 0.5px solid #5DDFA6;
  transition: border 650ms ease;
}
.aside .pathologies .button-overlay.s-margin {
  border: 0.5px solid #7C67F5;
  transition: border 650ms ease;
}
.aside .pathologies .button-overlay.s-radio {
  border: 0.5px solid #1E98FC;
  transition: border 650ms ease;
}
.aside .pathologies button {
  appearance: none;
  border: none;
  cursor: pointer;
  padding: 12px 13px 11px;
  border-radius: 12px;
  width: 100%;
  text-align: left;
}
.aside .pathologies button.carry {
  background: #FF40A7;
}
.aside .pathologies button.calculus {
  background: #5DDFA6;
}
.aside .pathologies button.margin {
  background: #7C67F5;
}
.aside .pathologies button.radio {
  background: #1E98FC;
}
.aside .pathologies button span {
  color: #F4F0EB;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 112.023%; /* 20.164px */
  padding-left: 30px;
  position: relative;
}
.aside .pathologies button span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  background: url("../../../public/images/add.svg") no-repeat center center;
  background-size: cover;
}
.aside #pathologies-list {
  margin-bottom: 14px;
  background: #3A4561;
  padding: 8px 13px 10px 9px;
  border: 1px solid rgba(144, 144, 144, 0.2);
  border-radius: 17px;
  opacity: 0;
}
.aside #pathologies-list.has-item {
  opacity: 1;
  transition: opacity 650ms ease;
}
.aside #pathologies-list .list-item {
  margin-bottom: 3px;
  padding: 20px 7px 14px 6px;
  background: rgba(99, 111, 140, 0.2);
  border-radius: 5px;
  position: relative;
}
.aside #pathologies-list .list-item:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  width: 4px;
  height: 38px;
  border-radius: 28px;
}
.aside #pathologies-list .list-item.carry:before {
  background: #FF40A7;
}
.aside #pathologies-list .list-item.calculus:before {
  background: #5DDFA6;
}
.aside #pathologies-list .list-item.margin:before {
  background: #7C67F5;
}
.aside #pathologies-list .list-item.radio:before {
  background: #1E98FC;
}
.aside #pathologies-list .list-item:last-child {
  margin-bottom: 0;
}
.aside #pathologies-list .list-item span {
  font-size: 16px;
  color: #F4F0EB;
  font-weight: 300;
  line-height: 112.023%; /* 17.924px */
  padding-left: 42px;
  position: relative;
}
.aside #pathologies-list .list-item span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url("../../../public/images/tooth.svg") no-repeat center center;
  background-size: cover;
}
.aside #pathologies-list .list-item .remove {
  appearance: none;
  cursor: pointer;
  padding: 0;
  border: none;
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  background: url("../../../public/images/remove.svg") no-repeat center center;
  background-size: cover;
}

@media only screen and (max-width: 769px) {
  .aside {
    padding: 30px 16px;
  }
  .aside .side-title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .aside .pathologies button span {
    font-size: 16px;
    line-height: 20px;
  }
}
#scoreboard {
  background: linear-gradient(180deg, #263465 0%, #252F4A 100%), #252D42;
  position: relative;
}
#scoreboard.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
#scoreboard #header {
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#scoreboard #header #logo {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
}
#scoreboard #header .is-mobile-cta {
  display: none;
}
#scoreboard .score-grid {
  padding: 0 16px 50px;
  display: grid;
  grid-template-columns: 1fr 345px;
  gap: 20px;
  min-height: calc(100vh - 73px);
  position: relative;
}
#scoreboard .score-grid .score-right {
  max-width: 345px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 16px;
}
#scoreboard .score-grid .score-right.fixed {
  position: fixed;
}
#scoreboard .score-grid .score-right .overflow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: scroll;
}
#scoreboard .block {
  border-radius: 23px;
  border: 1px solid #374882;
  background: rgba(55, 72, 130, 0.3);
  padding: 17px 15px 21px;
  margin-top: 20px;
}
#scoreboard .block.stats {
  display: grid;
  grid-template-columns: 331px 1fr;
  gap: 20px;
}
#scoreboard .block.has-demo {
  padding: 12px 13px 11px;
}
#scoreboard .graphs .graph {
  width: 100%;
  padding: 26px 20px 17px;
  border: 1px solid #374882;
  background: rgba(55, 72, 130, 0.3);
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
#scoreboard .graphs .graph.margin-btm {
  margin-bottom: 12px;
}
#scoreboard .graphs .graph #circle-a,
#scoreboard .graphs .graph #circle-b {
  z-index: 2;
}
#scoreboard .graphs .graph .circle {
  width: 150px;
  height: 153px;
  margin: 6px 0 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: top;
}
#scoreboard .graphs .graph .circle.is-ai {
  position: absolute;
  z-index: 1;
  top: 26px;
  left: 50%;
  transform: translateX(-50%);
}
#scoreboard .graphs .graph .circle .circle-stats {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#scoreboard .graphs .graph .circle .circle-stats strong {
  display: block;
  width: 100%;
  text-align: center;
  color: #F4F0EB;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 134.523%; /* 47.083px */
}
#scoreboard .graphs .graph .circle .circle-stats span {
  display: block;
  text-align: center;
  color: #40A9FF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 134.523%; /* 13.452px */
}
#scoreboard .graphs .graph .score-type {
  display: block;
  text-align: center;
  color: #E5F4FF;
  font-size: 25px;
  font-style: normal;
  font-weight: 550;
  line-height: 134.523%; /* 33.631px */
}
#scoreboard .score {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}
#scoreboard .score .score-title {
  text-align: center;
  color: #F4F0EB;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 30px;
}
#scoreboard .score .your-score {
  display: block;
  color: #E5F4FF;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  margin: 0 auto 14px;
  text-align: center;
}
#scoreboard .score strong {
  display: block;
  margin: 0 auto 20px;
  color: #40A9FF;
  text-align: center;
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: 110px;
}
#scoreboard .score p {
  color: #E5F4FF;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
#scoreboard .score p span {
  font-weight: 700;
}
#scoreboard .leader {
  display: block;
  color: #F4F0EB;
  font-size: 25px;
  font-weight: 500;
  line-height: 112.023%; /* 28.006px */
  padding-left: 75px;
  margin: 25px 0 40px;
  position: relative;
}
#scoreboard .leader:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 38px;
  height: 34px;
  background: url("../../../public/images/leaderboard.png") no-repeat center center;
  background-size: cover;
}
#scoreboard .leaderboard.is-top {
  margin-bottom: 4px;
}
#scoreboard .leaderboard.has-height {
  border-radius: 16.1px;
  overflow: scroll;
  max-height: 650px;
}
#scoreboard .leaderboard li {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 134.523%; /* 28.25px */
  padding: 14px;
  border-radius: 16.1px;
  margin-bottom: 4px;
}
#scoreboard .leaderboard li.has-full-infos {
  grid-template-columns: repeat(6, 1fr);
}
#scoreboard .leaderboard li:last-child {
  margin-bottom: 0;
}
#scoreboard .leaderboard li.top-list {
  font-weight: 500;
  color: #263464;
  background: rgba(255, 255, 255, 0.8);
}
#scoreboard .leaderboard li.top-list .top-leaderboard.has-info {
  position: relative;
}
#scoreboard .leaderboard li.top-list .top-leaderboard.has-info strong {
  position: relative;
  padding-right: 20px;
}
#scoreboard .leaderboard li.top-list .top-leaderboard.has-info strong:after {
  cursor: pointer;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  background: url("../../../public/images/infos.png") no-repeat center center;
  background-size: cover;
  border-radius: 50%;
}
#scoreboard .leaderboard li.top-list .top-leaderboard.has-info strong:hover .info-box {
  opacity: 1;
  transition: opacity 650ms ease;
}
#scoreboard .leaderboard li.top-list .top-leaderboard.has-info .info-box {
  position: absolute;
  padding: 6px;
  background: #40A9FF;
  color: #F4F0EB;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  font-size: 12px;
  line-height: 16px;
  opacity: 0;
  border-radius: 8px;
}
#scoreboard .leaderboard li.scores {
  background: #374882;
  color: #E5F4FF;
}
#scoreboard .leaderboard li.scores.my-score {
  background: #40A9FF;
}
#scoreboard .get-demo {
  background: #40A9FF;
  padding: 20px 28px;
  border-radius: 27px;
  border: 1px solid #7DC5FF;
}
#scoreboard .get-demo img {
  width: 58.6px;
  height: auto;
  margin: 0 auto 15px;
}
#scoreboard .get-demo h2 {
  color: #E5F4FF;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 550;
  line-height: 38px;
  margin-bottom: 15px;
}
#scoreboard .get-demo strong {
  display: block;
  text-align: center;
  color: #F4F0EB;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.523%; /* 80.714px */
  margin-bottom: 15px;
}
#scoreboard .solutions img {
  border-radius: 11px;
  margin-bottom: 16px;
}
#scoreboard #solutions-panel {
  position: fixed;
  overflow: scroll;
  background: #000000;
  z-index: 2;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100vh;
  transition: left 650ms ease;
}
#scoreboard #solutions-panel:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000000;
}
#scoreboard #solutions-panel.slide {
  left: 0;
}
#scoreboard #solutions-panel .top-solutions {
  display: grid;
  grid-template-columns: 250px 1fr;
  position: relative;
  z-index: 3;
}
#scoreboard #solutions-panel .top-solutions .left {
  padding: 16px;
  background: #2D3F7B;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#scoreboard #solutions-panel .top-solutions .left .close-solutions {
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
}
#scoreboard #solutions-panel .top-solutions .left .close-solutions span {
  color: #F4F0EB;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding-left: 60px;
  text-transform: uppercase;
  position: relative;
}
#scoreboard #solutions-panel .top-solutions .left .close-solutions span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(180deg);
  width: 41.167px;
  height: 38px;
  background: url("../../../public/images/arrow-black.svg") no-repeat center center;
  background-size: cover;
}
#scoreboard #solutions-panel .top-solutions .right {
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #40A9FF;
}
#scoreboard #solutions-panel .top-solutions .right h2 {
  color: #E5F4FF;
  font-size: 22px;
  line-height: 26px;
  font-style: normal;
  margin-right: 80px;
}
#scoreboard #solutions-panel .top-solutions .right h2 strong {
  font-weight: 700;
}
#scoreboard #solutions-panel #x-ray-one-overlays.your-overlay-one .clean-overlay-one,
#scoreboard #solutions-panel #x-ray-one-overlays.your-overlay-one .ai-circles-overlay {
  display: none;
}
#scoreboard #solutions-panel #x-ray-one-overlays.true-overlay-one .clean-overlay-one {
  display: block;
}
#scoreboard #solutions-panel #x-ray-one-overlays.true-overlay-one .your-findings,
#scoreboard #solutions-panel #x-ray-one-overlays.true-overlay-one .ai-findings {
  display: none;
}
#scoreboard #solutions-panel #x-ray-one-overlays.ai-overlay-one .ai-findings svg {
  position: absolute;
  display: block;
  z-index: 99;
}
#scoreboard #solutions-panel #x-ray-one-overlays.ai-overlay-one .your-findings {
  display: none;
}
#scoreboard #solutions-panel #x-ray-two-overlays.your-overlay-two .clean-overlay-two,
#scoreboard #solutions-panel #x-ray-two-overlays.your-overlay-two .ai-circles-overlay {
  display: none;
}
#scoreboard #solutions-panel #x-ray-two-overlays.true-overlay-two .clean-overlay-two {
  display: block;
}
#scoreboard #solutions-panel #x-ray-two-overlays.true-overlay-two .your-findings,
#scoreboard #solutions-panel #x-ray-two-overlays.true-overlay-two .ai-findings {
  display: none;
}
#scoreboard #solutions-panel #x-ray-two-overlays.ai-overlay-two .ai-findings,
#scoreboard #solutions-panel #x-ray-two-overlays.ai-overlay-two .clean-overlay-two {
  display: block;
}
#scoreboard #solutions-panel #x-ray-two-overlays.ai-overlay-two .your-findings {
  display: none;
}
#scoreboard #solutions-panel #x-ray-three-overlays.your-overlay-three .clean-overlay-three,
#scoreboard #solutions-panel #x-ray-three-overlays.your-overlay-three .ai-circles-overlay {
  display: none;
}
#scoreboard #solutions-panel #x-ray-three-overlays.true-overlay-three .clean-overlay-three {
  display: block;
}
#scoreboard #solutions-panel #x-ray-three-overlays.true-overlay-three .your-findings,
#scoreboard #solutions-panel #x-ray-three-overlays.true-overlay-three .ai-findings {
  display: none;
}
#scoreboard #solutions-panel #x-ray-three-overlays.ai-overlay-three .ai-findings,
#scoreboard #solutions-panel #x-ray-three-overlays.ai-overlay-three .clean-overlay-three {
  display: block;
}
#scoreboard #solutions-panel #x-ray-three-overlays.ai-overlay-three .your-findings {
  display: none;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result {
  display: grid !important;
  grid-template-columns: 1fr 430px;
  min-height: calc(100vh - 73px);
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left {
  background: #000000;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left .max-container {
  max-width: 1152px;
  width: 100%;
  margin: 0 auto;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left .img-result-container {
  width: 100%;
  height: auto;
  aspect-ratio: 1.42;
  position: relative;
  overflow: hidden;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left .clean-overlay-one,
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left .clean-overlay-two,
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left .clean-overlay-three {
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left .ai-circles-overlay {
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: 100%;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left .solution-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left .solution-overlay .pathos-desc {
  opacity: 0;
  transition: opacity 650ms ease;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-left .solution-overlay .pathos-desc.see-patho {
  opacity: 1;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right {
  position: relative;
  background: linear-gradient(16deg, #252D42 -14.76%, #263465 96.36%);
  padding: 30px 33px 30px;
  overflow: scroll;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right h3 {
  text-align: center;
  color: #F4F0EB;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  margin-bottom: 20px;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .result-block {
  padding: 15px;
  border-radius: 17px;
  border: 1px solid rgba(144, 144, 144, 0.2);
  margin-bottom: 40px;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block {
  padding: 15px;
  border-radius: 5px;
  position: relative;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block.no-click {
  margin-bottom: 15px;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block.no-click:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed rgba(144, 144, 144, 0.2);
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block.clickable {
  cursor: pointer;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block.clickable:before {
  content: "";
  position: absolute;
  top: 19px;
  right: 20px;
  width: 16px;
  height: 16px;
  background: url("../../../public/images/eye-off.svg") no-repeat center center;
  background-size: cover;
  transition: all 500ms ease;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block.current {
  border-radius: 5px;
  background: #4F5A7D;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block.current:before {
  background: url("../../../public/images/eye-on.svg") no-repeat center center;
  background-size: cover;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block h4 {
  color: #F4F0EB;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 18px;
  padding-left: 35px;
  position: relative;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block h4.true:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url("../../../public/images/tooth.svg") no-repeat center center;
  background-size: cover;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block h4.your:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url("../../../public/images/your-icon.svg") no-repeat center center;
  background-size: cover;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block h4.ai:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 19px;
  background: url("../../../public/images/ai-icon-v2.svg") no-repeat center center;
  background-size: contain;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block .findings li {
  color: #F4F0EB;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  margin-bottom: 8px;
  padding-left: 35px;
  position: relative;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block .findings li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block .findings li:last-child {
  margin-bottom: 0;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block .findings li.carry:before {
  background: #FF40A7;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block .findings li.radio:before {
  background: #40A9FF;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block .findings li.calculus:before {
  background: #5DDFA6;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block .findings li.margin:before {
  background: #7C67F5;
}
#scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block .findings li.your:before {
  background: #23BEE2;
}
#scoreboard #solutions-panel .custom-arrows-container {
  position: absolute;
  right: 120px;
  bottom: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
#scoreboard #solutions-panel .custom-arrows-container .custom-arrow {
  margin: 0 25px;
  cursor: pointer;
}
#scoreboard #solutions-panel .custom-arrows-container .custom-arrow.slick-disabled {
  opacity: 0.5;
  pointer-events: none;
}
#scoreboard #solutions-panel .custom-arrows-container .custom-arrow.next span:before {
  transform: translateX(-50%) rotate(180deg);
}
#scoreboard #solutions-panel .custom-arrows-container .custom-arrow span {
  display: block;
  color: #F4F0EB;
  font-size: 22px;
  font-weight: 400;
  padding-bottom: 60px;
  position: relative;
}
#scoreboard #solutions-panel .custom-arrows-container .custom-arrow span:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background: url("../../../public/images/slider-arrow.svg") no-repeat center center;
  background-size: cover;
}
#scoreboard #solutions-panel .get-demo-panel {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#scoreboard #solutions-panel .get-demo-panel.show {
  display: flex;
  animation: opacityAnimation 750ms;
}
#scoreboard #solutions-panel .get-demo-panel .demo-container {
  max-width: 750px;
  width: 100%;
  background: #3A4561;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 25px 15px 26px;
  position: relative;
}
#scoreboard #solutions-panel .get-demo-panel .demo-container .sup {
  font-size: 18px;
  position: relative;
  top: -8px;
}
#scoreboard #solutions-panel .get-demo-panel .demo-container .close-demo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  appearance: none;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}
#scoreboard #solutions-panel .get-demo-panel .demo-container .close-demo:after, #scoreboard #solutions-panel .get-demo-panel .demo-container .close-demo:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background: #F4F0EB;
}
#scoreboard #solutions-panel .get-demo-panel .demo-container .close-demo:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
#scoreboard #solutions-panel .get-demo-panel .demo-container .close-demo:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
#scoreboard #solutions-panel .get-demo-panel .demo-container h2 {
  text-align: center;
  color: #F4F0EB;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.523%; /* 32.285px */
  margin-bottom: 20px;
}
#scoreboard #solutions-panel .get-demo-panel .demo-container .hbspt-form .hs-form-field label {
  margin-bottom: 8px !important;
}
#scoreboard #solutions-panel .get-demo-panel .demo-container .hbspt-form .legal-consent-container {
  color: #F4F0EB;
  font-size: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1025px) {
  #scoreboard {
    overflow: hidden;
  }
  #scoreboard #header .is-mobile-cta {
    display: block;
  }
  #scoreboard .score-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  #scoreboard .score-grid .score-right {
    display: none;
  }
  #scoreboard .leaderboard li.top-list .top-leaderboard.has-info.p-info .info-box {
    transform: none;
    left: auto;
    right: 0;
  }
  #scoreboard #solutions-panel {
    position: absolute;
    height: 100%;
    background: linear-gradient(16deg, #252D42 -14.76%, #263465 96.36%);
  }
  #scoreboard #solutions-panel:before {
    display: none;
  }
  #scoreboard #solutions-panel .top-solutions .right {
    justify-content: flex-end;
  }
  #scoreboard #solutions-panel .top-solutions .right h2 {
    font-size: 16px;
    line-height: 18px;
    margin-right: 10px;
  }
  #scoreboard #solutions-panel .top-solutions .right button {
    min-width: 110px;
    padding: 14px 15px 13px;
  }
  #scoreboard #solutions-panel .solutions-slider .solutions-result {
    min-height: auto;
    grid-template-columns: repeat(1, 1fr);
  }
  #scoreboard #solutions-panel .solutions-slider .solutions-result .result-right {
    background: none;
  }
  #scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .result-block {
    margin-bottom: 0;
  }
  #scoreboard #solutions-panel .custom-arrows-container {
    right: auto;
    position: relative;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 769px) {
  #scoreboard .block.stats {
    grid-template-columns: repeat(1, 1fr);
  }
  #scoreboard .block.stats .graphs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 16px;
  }
  #scoreboard .block.stats .graphs .graph {
    height: max-content;
    max-width: 220px;
  }
  #scoreboard .block.stats .graphs .graph.margin-btm {
    margin-bottom: 0;
  }
  #scoreboard .block.stats .graphs .graph .circle {
    width: 100px;
    height: 100px;
  }
  #scoreboard .block.stats .graphs .graph .circle canvas {
    width: 100px !important;
    height: 100px !important;
  }
  #scoreboard .block.stats .graphs .graph .circle .circle-stats strong {
    font-size: 28px;
    line-height: 32px;
  }
  #scoreboard .block.stats .graphs .graph .circle .circle-stats span {
    font-size: 10px;
  }
  #scoreboard .block.stats .graphs .graph .score-type {
    font-size: 20px;
    line-height: 24px;
  }
  #scoreboard .score .score-title {
    font-size: 34px;
    line-height: 36px;
    margin-bottom: 16px;
  }
  #scoreboard .score .your-score {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 0;
  }
  #scoreboard .score strong {
    font-size: 70px;
    line-height: 80px;
    margin: 0 14px;
  }
  #scoreboard .score p {
    font-size: 18px;
    line-height: 22px;
  }
  #scoreboard .leader {
    font-size: 20px;
    line-height: 24px;
    margin: 20px 0;
  }
  #scoreboard #solutions-panel .solutions-slider .solutions-result .result-right {
    padding: 30px 16px;
  }
  #scoreboard #solutions-panel .solutions-slider .solutions-result .result-right h3 {
    font-size: 24px;
    line-height: 26px;
  }
  #scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block h4 {
    font-size: 18px;
  }
  #scoreboard #solutions-panel .solutions-slider .solutions-result .result-right .findings-block .findings li {
    font-size: 16px;
  }
  #scoreboard #solutions-panel .get-demo-panel .demo-container h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  #scoreboard #solutions-panel .get-demo-panel .demo-container .hbspt-form .hs-form-field {
    margin-bottom: 10px;
  }
  #scoreboard #solutions-panel .get-demo-panel .demo-container .hbspt-form .hs-form-field label {
    font-size: 14px;
    margin-bottom: 2px !important;
  }
  #scoreboard #solutions-panel .get-demo-panel .demo-container .hbspt-form .hs-form-field input,
  #scoreboard #solutions-panel .get-demo-panel .demo-container .hbspt-form .hs-form-field select {
    height: 25px;
  }
  #scoreboard #solutions-panel .get-demo-panel .demo-container .hbspt-form .hs-country:before,
  #scoreboard #solutions-panel .get-demo-panel .demo-container .hbspt-form .imaging_software_new:before {
    top: 30px;
  }
  #scoreboard #solutions-panel .get-demo-panel .demo-container .hbspt-form .actions .hs-button {
    margin-top: 0;
    font-size: 14px;
    padding: 10px;
  }
}
@media only screen and (max-width: 641px) {
  #scoreboard .leaderboard.has-height {
    max-height: 400px;
  }
  #scoreboard .leaderboard li {
    font-size: 11px;
    padding: 8px 4px;
  }
  #scoreboard .leaderboard li.top-list .top-leaderboard.has-info strong:after {
    display: none;
  }
  #scoreboard .leaderboard li.top-list .top-leaderboard.has-info .info-box {
    display: none;
  }
  #scoreboard #solutions-panel .top-solutions {
    grid-template-columns: 60px 1fr;
  }
  #scoreboard #solutions-panel .top-solutions .left .close-solutions span {
    font-size: 0;
  }
  #scoreboard #solutions-panel .top-solutions .left .close-solutions span:before {
    left: 6px;
  }
}
#login-screen {
  background: #112542;
  overflow: hidden;
}
#login-screen .login-layer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 100px;
}
#login-screen .login-layer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 470px;
  height: 345px;
  background: url("../../../public/images/login-layer-1.svg") no-repeat center center;
  background-size: cover;
}
#login-screen .login-layer:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 683px;
  height: 604px;
  background: url("../../../public/images/login-layer-2.svg") no-repeat center center;
  background-size: cover;
}
#login-screen .login-logo {
  display: block;
  width: 116px;
  height: 87.8px;
  margin-bottom: 80px;
  position: relative;
  z-index: 2;
}
#login-screen .login-title {
  text-align: center;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 80px;
  position: relative;
}
#login-screen .login-title:after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 86px;
  height: 2px;
  background: #40A9FF;
}
#login-screen .login-list {
  margin: 16px 0;
}
#login-screen .login-list li a {
  display: block;
  margin-bottom: 5px;
  color: #40A9FF;
}
#login-screen .login-form {
  position: relative;
}
#login-screen .login-form:before {
  content: "";
  position: absolute;
  width: 630px;
  height: 630px;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  background: linear-gradient(180deg, #23BEE2 0%, #112542 100%);
  filter: blur(150px);
}
#login-screen .login-form .form-row {
  position: relative;
  overflow: hidden;
  width: 100%;
}
#login-screen .login-form .form-row.email:before {
  content: "";
  position: absolute;
  top: 12px;
  left: 15px;
  width: 14px;
  height: 14px;
  background: url("../../../public/images/login-mail.svg") no-repeat center center;
  background-size: cover;
}
#login-screen .login-form .form-row #pwd-reveal {
  display: block;
  position: absolute;
  top: 12px;
  right: 15px;
  width: 18px;
  height: 18px;
  background: url("../../../public/images/login-pwd.svg") no-repeat center center;
  background-size: cover;
  cursor: pointer;
}
#login-screen .login-form label {
  position: absolute;
  left: -100%;
}
#login-screen .login-form input {
  width: 100%;
  height: 40px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}
#login-screen .login-form input[type=email] {
  padding: 2px 40px;
}
#login-screen .login-form input[type=password],
#login-screen .login-form input[type=text] {
  padding: 2px 40px 2px 15px;
}
#login-screen .login-form button {
  width: 100%;
  height: 46px;
  background: #40A9FF;
  border: 1px solid #40A9FF;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  cursor: pointer;
}
#login-screen .form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  background: #ffffff;
  padding: 60px;
  border-radius: 26px;
}

.ea body {
  background-color: #080710;
}
.ea .background {
  height: 100vh;
  width: 100vw;
  background: url("../../../public/images/intro-bkg.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  z-index: 10;
}
.ea .actions {
  color: #F4F0EB;
}
.ea form {
  height: 520px;
  width: 400px;
  background-color: #2D3F7B;
  border-radius: 10px;
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}
.ea form {
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}
.ea label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
}
.ea input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  color: #F4F0EB;
}
.ea ::placeholder {
  color: #F4F0EB;
}
.ea button {
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #2D3F7B;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

#error {
  min-height: 100vh;
  height: 100%;
  background: #112542;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
#error .error-logo {
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 30px;
  width: 162.17px;
  height: 127px;
}
#error .error-inside {
  position: relative;
  padding: 0 30px;
}
#error .error-inside:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 630px;
  height: 630px;
  background: linear-gradient(180deg, #23BEE2 0%, #112542 100%);
  filter: blur(250px);
  border-radius: 50%;
}
#error .error-img {
  text-align: center;
  position: relative;
  z-index: 2;
  display: block;
  max-width: 800px;
  width: 100%;
  margin: 0 auto 100px;
}
#error .error-img-bis {
  text-align: center;
  position: relative;
  z-index: 2;
  display: block;
  max-width: 120px;
  width: 100%;
  margin: 0 auto;
}
#error .error-title {
  text-align: center;
  position: relative;
  z-index: 2;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  color: #F4F0EB;
}
#error .error-title:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 151px;
  height: 5px;
  background: #23BEE2;
}
#error .error-title.no-border:before {
  display: none;
}
#error .error-big-title {
  position: relative;
  z-index: 2;
  text-align: center;
  font-weight: 700;
  font-size: 200px;
  line-height: 242px;
  color: #F4F0EB;
}
#error .error-big-title:before {
  content: "";
  position: absolute;
  bottom: -120px;
  left: -300px;
  width: 237px;
  height: 211px;
  background: url("../../../public/images/teeth-blue-1.svg") no-repeat center center;
  background-size: cover;
}
#error .error-big-title:after {
  content: "";
  position: absolute;
  top: -120px;
  right: -200px;
  width: 177px;
  height: 218px;
  background: url("../../../public/images/teeth-blue-2.svg") no-repeat center center;
  background-size: cover;
}

@media only screen and (max-width: 769px) {
  #error .error-inside:before {
    width: 315px;
    height: 315px;
  }
  #error .error-title {
    font-size: 40px;
    line-height: 53px;
  }
  #error .error-big-title {
    font-size: 120px;
    line-height: 150px;
  }
}
#session-end {
  min-height: 100vh;
  height: 100%;
  background: #112542;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
#session-end:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 539px;
  height: 303px;
  background: url("../../../public/images/layer-hero-1.svg") no-repeat center center;
  background-size: cover;
}
#session-end:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 683px;
  height: 608px;
  background: url("../../../public/images/layer-hero-2.svg") no-repeat center center;
  background-size: cover;
}
#session-end .end-logo {
  position: relative;
  z-index: 2;
  width: 274.54px;
  height: 215px;
  margin: 0 auto 220px;
}
#session-end .end-inside {
  position: relative;
  padding: 0 30px;
  text-align: center;
}
#session-end .end-inside:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 630px;
  height: 630px;
  background: linear-gradient(180deg, #23BEE2 0%, #112542 100%);
  filter: blur(250px);
  border-radius: 50%;
}
#session-end .end-title {
  text-align: center;
  position: relative;
  z-index: 2;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  color: #F4F0EB;
}
#session-end .end-title:before {
  content: "";
  position: absolute;
  top: -210px;
  left: 50%;
  transform: translateX(-60%);
  width: 286px;
  height: 212px;
  background: url("../../../public/images/check.svg") no-repeat center center;
  background-size: cover;
}
#session-end .button {
  display: inline-block;
  margin: 30px auto;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 769px) {
  #session-end:before {
    width: 269.5px;
    height: 151.5px;
  }
  #session-end:after {
    width: 341.5px;
    height: 304px;
  }
  #session-end .end-inside:before {
    width: 315px;
    height: 315px;
  }
  #session-end .end-title {
    font-size: 40px;
    line-height: 53px;
  }
}