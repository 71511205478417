@charset "UTF-8";

#header{
  background: $base-color3;
  padding: 20px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  #logo{
    display: block;
    width: 116px;
    height: 87.8px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  .cta-container{
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    &.remove{
      display: none;
    }

    #first-radio,
    #second-radio,
    #third-radio{

      &.inactive{
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    #first-radio{

      &.step-done{
        display: none;
      }
    }

    #second-radio{
      display: none;

      &.step-to-do{
        display: block;

        &.step-done{
          display: none;
        }
      }
    }

    #third-radio{
      display: none;

      &.step-to-submit{
        display: block;
      }
    }
  }

  .ray-title{
    color: $base-color1;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 20px;
    text-align: center;
  }

  .breadcrumps{
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
  }

  .marker{
    display: flex;
    width: 100%;
    height: 16px;
    border-radius: 55px;
    background: #1A2031;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 33.33%;
      height: 16px;
      background: $base-color4;
      border-radius: 55px;
      transition: width 650ms ease;
    }

    &.second{

      &:before{
        width: 66.66%;
      }

      &.end{

        &:before{
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-landscape+1px) {

  #header{

    .cta-container{
      top:  16px;
      transform: none;
    }
  }
}

@media only screen and (max-width: $tablet-portrait+1px) {

  #header{
    padding: 30px 16px 80px;

    #logo{
      top: 0;
      left: 0;
      transform: none;
    }

    .ray-title{
      font-size: 30px;
      line-height: 34px;
      text-align: right;
    }

    .cta-container{
      top: auto;
      bottom: 16px;
    }
  }
}