@charset "UTF-8";

#session-end{
  min-height: 100vh;
  height: 100%;
  background: #112542;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 539px;
    height: 303px;
    background: url("#{$images_dir}layer-hero-1.svg") no-repeat center center;
    background-size: cover;
  }

  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 683px;
    height: 608px;
    background: url("#{$images_dir}layer-hero-2.svg") no-repeat center center;
    background-size: cover;
  }

  .end-logo{
    position: relative;
    z-index: 2;
    width: 274.54px;
    height: 215px;
    margin: 0 auto 220px;
  }

  .end-inside{
    position: relative;
    padding: 0 30px;
    text-align: center;

    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 630px;
      height: 630px;
      background: linear-gradient(180deg, #23BEE2 0%, #112542 100%);
      filter: blur(250px);
      border-radius: 50%;
    }
  }

  .end-title{
    text-align: center;
    position: relative;
    z-index: 2;
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
    color: $base-color1;

    &:before{
      content: "";
      position: absolute;
      top: -210px;
      left: 50%;
      transform: translateX(-60%);
      width: 286px;
      height: 212px;
      background: url("#{$images_dir}check.svg") no-repeat center center;
      background-size: cover;
    }
  }

  .button{
    display: inline-block;
    margin: 30px auto;
    position: relative;
    z-index: 2;
  }
}

@media only screen and (max-width: $tablet-portrait+1px) {

  #session-end{

    &:before{
      width: 269.5px;
      height: 151.5px;
    }

    &:after{
      width: 341.5px;
      height: 304px;
    }

    .end-inside{

      &:before{
        width: 315px;
        height: 315px;
      }
    }

    .end-title{
      font-size: 40px;
      line-height: 53px;
    }
  }
}