@charset "UTF-8";

#error{
  min-height: 100vh;
  height: 100%;
  background: #112542;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .error-logo{
    position: absolute;
    z-index: 2;
    top: 30px;
    left: 30px;
    width: 162.17px;
    height: 127px;
  }

  .error-inside{
    position: relative;
    padding: 0 30px;

    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 630px;
      height: 630px;
      background: linear-gradient(180deg, #23BEE2 0%, #112542 100%);
      filter: blur(250px);
      border-radius: 50%;
    }
  }

  .error-img{
    text-align: center;
    position: relative;
    z-index: 2;
    display: block;
    max-width: 800px;
    width: 100%;
    margin: 0 auto 100px;
  }

  .error-img-bis{
    text-align: center;
    position: relative;
    z-index: 2;
    display: block;
    max-width: 120px;
    width: 100%;
    margin: 0 auto;
  }

  .error-title{
    text-align: center;
    position: relative;
    z-index: 2;
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
    color: $base-color1;

    &:before{
      content: "";
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      width: 151px;
      height: 5px;
      background: #23BEE2;
    }

    &.no-border{

      &:before{
        display: none;
      }
    }
  }

  .error-big-title{
    position: relative;
    z-index: 2;
    text-align: center;
    font-weight: 700;
    font-size: 200px;
    line-height: 242px;
    color: $base-color1;

    &:before{
      content: "";
      position: absolute;
      bottom: -120px;
      left: -300px;
      width: 237px;
      height: 211px;
      background: url("#{$images_dir}teeth-blue-1.svg") no-repeat center center;
      background-size: cover;
    }

    &:after{
      content: "";
      position: absolute;
      top: -120px;
      right: -200px;
      width: 177px;
      height: 218px;
      background: url("#{$images_dir}teeth-blue-2.svg") no-repeat center center;
      background-size: cover;
    }
  }
}

@media only screen and (max-width: $tablet-portrait+1px) {

  #error{

    .error-inside{

      &:before{
        width: 315px;
        height: 315px;
      }
    }

    .error-title{
      font-size: 40px;
      line-height: 53px;
    }

    .error-big-title{
      font-size: 120px;
      line-height: 150px;
    }
  }
}