@charset "UTF-8";

body {
  position: relative;
  background: $base-color2;
  font-family: 'Roboto', sans-serif;

  //&.overflow{
  //  overflow: hidden;
  //}
}

@-webkit-keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes loaderAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.centering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inside {
  position: relative;
  padding: 0 100px;
}

img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.text {
  font-weight: 300;
  line-height: 152.523%; /* 24.404px */

  &.white {
    color: $base-color1
  }

  &.margin-btm {
    margin-bottom: 20px;
  }

  strong{
    font-weight: bold;
  }
}

.button {
  appearance: none;
  border: none;
  cursor: pointer;
  border-radius: 66px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;

  &.basic {
    padding: 14px 30px 13px;
  }

  &.small {
    font-size: 16px;
    padding: 14px 30px 13px;
  }

  &.arrow {
    padding: 14px 50px 13px 30px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      width: 28.5px;
      height: 27.5px;
      background: url("#{$images_dir}arrow.svg") no-repeat center center;
      background-size: cover;
      transition: all 650ms ease;
    }

    &:hover {

      &:before {
        right: 10px;
        background: url("#{$images_dir}arrow-blue.svg") no-repeat center center;
        background-size: cover;
      }
    }
  }

  &.blue {
    color: $base-color1;
    background: $base-color4;
    border: 1px solid $base-color4;

    &:hover {
      color: $base-color4;
      background: $base-color1;
      transition: all 650ms ease;
    }
  }

  &.white {
    color: $base-color4;
    background: $base-color1;
    border: 1px solid $base-color1;

    &:hover {
      color: $base-color1;
      background: transparent;
      transition: all 650ms ease;
    }
  }
}

.hbspt-form {

  .hs-submit {

    &.locked {
      cursor: not-allowed;

      .hs-button {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .hs-form-field {
    margin-bottom: 14px;

    label {
      display: block;
      color: $base-color1 !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 550;
      line-height: 134.523%; /* 21.524px */

      &.hs-error-msg {
        font-size: 12px;
        font-weight: normal;
        color: red !important;
      }
    }

    .hs-input {
      appearance: none;
      cursor: pointer;
      border: none;
      padding: 5px 20px;
      width: 100%;
      height: 40px;
      background: #D9D9D9;
      border-radius: 5px;
    }
  }

  .hs-country,
  .hs-imaging_software_new{
    position: relative;

    &:before{
      content: "";
      position: absolute;
      top: 45px;
      right: 20px;
      width: 10px;
      height: 10px;
      background: url("#{$images_dir}dropdown.png") no-repeat center center;
      background-size: cover;
    }
  }

  .submitted-message {
    text-align: center;
    color: $base-color1;
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hs-button {
      appearance: none;
      cursor: pointer;
      border-radius: 66px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 22px;
      text-decoration: none;
      padding: 14px 14px 13px;
      color: $base-color1;
      background: $base-color4;
      border: 1px solid $base-color4;
      margin-top: 22px;

      &:hover {
        color: $base-color4;
        background: $base-color1;
        transition: all 650ms ease;
      }
    }
  }
}

@media only screen and (max-width: $tablet-portrait+1px) {

  .button{
    font-size: 16px;
    line-height: 20px;

    &.small{
      font-size: 14px;
    }
  }
}