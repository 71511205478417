@charset "UTF-8";

$images_dir: "../../../public/images/";

/* BREAKPOINTS */

$desktop-large:    1920;
$desktop-medium:   1440;
$desktop-small:    1280;
$tablet-landscape: 1024;
$tablet-portrait:  768;
$mobile-landscape: 640;
$mobile-portrait:  420;

/* COLORS */

$base-color1: #F4F0EB;
$base-color2: #000000;
$base-color3: #2D3F7B;
$base-color4: #40A9FF;
$base-color5: #FF40A7;
$base-color6: #5DDFA6;
$base-color7: #7C67F5;
$base-color8: #1E98FC;