@charset "UTF-8";

#scoreboard {
  background: linear-gradient(180deg, #263465 0%, #252F4A 100%), #252D42;
  position: relative;

  &.fixed{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  #header {
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    #logo {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }

    .is-mobile-cta{
      display: none;
    }
  }

  .score-grid {
    padding: 0 16px 50px;
    display: grid;
    grid-template-columns: 1fr 345px;
    gap: 20px;
    min-height: calc(100vh - 73px);
    position: relative;

    .score-right {
      max-width: 345px;
      width: 100%;
      position: absolute;
      top: 0;
      right: 16px;

      &.fixed {
        position: fixed;
      }

      .overflow{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: scroll;
      }
    }
  }

  .block {
    border-radius: 23px;
    border: 1px solid #374882;
    background: rgba(55, 72, 130, 0.30);
    padding: 17px 15px 21px;
    margin-top: 20px;

    &.stats {
      display: grid;
      grid-template-columns: 331px 1fr;
      gap: 20px;
    }

    &.has-demo {
      padding: 12px 13px 11px;
    }
  }

  .graphs {

    .graph {
      width: 100%;
      padding: 26px 20px 17px;
      border: 1px solid #374882;
      background: rgba(55, 72, 130, 0.30);
      border-radius: 23px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      &.margin-btm {
        margin-bottom: 12px;
      }

      #circle-a,
      #circle-b {
        z-index: 2;
      }

      .circle {
        width: 150px;
        height: 153px;
        margin: 6px 0 20px;
        display: inline-block;
        position: relative;
        text-align: center;
        vertical-align: top;

        &.is-ai {
          position: absolute;
          z-index: 1;
          top: 26px;
          left: 50%;
          transform: translateX(-50%);
        }

        .circle-stats {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          strong {
            display: block;
            width: 100%;
            text-align: center;
            color: $base-color1;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: 134.523%; /* 47.083px */
          }

          span {
            display: block;
            text-align: center;
            color: $base-color4;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 134.523%; /* 13.452px */
          }
        }
      }

      .score-type {
        display: block;
        text-align: center;
        color: #E5F4FF;
        font-size: 25px;
        font-style: normal;
        font-weight: 550;
        line-height: 134.523%; /* 33.631px */
      }
    }
  }

  .score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    .score-title {
      text-align: center;
      color: $base-color1;
      font-size: 44px;
      font-style: normal;
      font-weight: 500;
      line-height: 48px;
      margin-bottom: 30px;
    }

    .your-score {
      display: block;
      color: #E5F4FF;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
      margin: 0 auto 14px;
      text-align: center;
    }

    strong {
      display: block;
      margin: 0 auto 20px;
      color: $base-color4;
      text-align: center;
      font-size: 100px;
      font-style: normal;
      font-weight: 700;
      line-height: 110px;
    }

    p {
      color: #E5F4FF;
      text-align: center;;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;

      span {
        font-weight: 700;
      }
    }
  }

  .leader {
    display: block;
    color: $base-color1;
    font-size: 25px;
    font-weight: 500;
    line-height: 112.023%; /* 28.006px */
    padding-left: 75px;
    margin: 25px 0 40px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      width: 38px;
      height: 34px;
      background: url("#{$images_dir}leaderboard.png") no-repeat center center;
      background-size: cover;
    }
  }

  .leaderboard {

    &.is-top {
      margin-bottom: 4px;
    }

    &.has-height {
      border-radius: 16.1px;
      overflow: scroll;
      max-height: 650px;
    }

    li {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      text-align: center;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 134.523%; /* 28.25px */
      padding: 14px;
      border-radius: 16.1px;
      margin-bottom: 4px;

      &.has-full-infos{
        grid-template-columns: repeat(6, 1fr);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.top-list {
        font-weight: 500;
        color: #263464;
        background: rgba(255, 255, 255, 0.80);

        .top-leaderboard{

          &.has-info{
            position: relative;

            strong{
              position: relative;
              padding-right: 20px;

              &:after{
                cursor: pointer;
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 14px;
                height: 14px;
                background: url("#{$images_dir}infos.png") no-repeat center center;
                background-size: cover;
                border-radius: 50%;
              }

              &:hover{

                .info-box{
                  opacity: 1;
                  transition: opacity 650ms ease;
                }
              }
            }

            .info-box{
              position: absolute;
              padding: 6px;
              background: $base-color4;
              color: $base-color1;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
              width: 250px;
              font-size: 12px;
              line-height: 16px;
              opacity: 0;
              border-radius: 8px;
            }
          }
        }
      }

      &.scores {
        background: #374882;
        color: #E5F4FF;

        &.my-score {
          background: $base-color4;
        }
      }
    }
  }

  .get-demo {
    background: $base-color4;
    padding: 20px 28px;
    border-radius: 27px;
    border: 1px solid #7DC5FF;

    img {
      width: 58.6px;
      height: auto;
      margin: 0 auto 15px;
    }

    h2 {
      color: #E5F4FF;
      text-align: center;
      font-size: 34px;
      font-style: normal;
      font-weight: 550;
      line-height: 38px;
      margin-bottom: 15px;
    }

    strong {
      display: block;
      text-align: center;
      color: $base-color1;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 134.523%; /* 80.714px */
      margin-bottom: 15px;
    }
  }

  .solutions {

    img {
      border-radius: 11px;
      margin-bottom: 16px;
    }
  }

  #solutions-panel {
    position: fixed;
    overflow: scroll;
    background: $base-color2;
    z-index: 2;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    transition: left 650ms ease;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: $base-color2;
    }

    &.slide {
      left: 0;
    }

    .top-solutions {
      display: grid;
      grid-template-columns: 250px 1fr;
      position: relative;
      z-index: 3;

      .left {
        padding: 16px;
        background: $base-color3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .close-solutions {
          appearance: none;
          cursor: pointer;
          background: none;
          border: none;

          span {
            color: $base-color1;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            padding-left: 60px;
            text-transform: uppercase;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%) rotate(180deg);
              width: 41.167px;
              height: 38px;
              background: url("#{$images_dir}arrow-black.svg") no-repeat center center;
              background-size: cover;
            }
          }
        }
      }

      .right {
        padding: 10px 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: $base-color4;

        h2 {
          color: #E5F4FF;
          font-size: 22px;
          line-height: 26px;
          font-style: normal;
          margin-right: 80px;

          strong {
            font-weight: 700;
          }
        }
      }
    }

    #x-ray-one-overlays {

      &.your-overlay-one {

        .clean-overlay-one,
        .ai-circles-overlay {
          display: none;
        }
      }

      &.true-overlay-one {

        .clean-overlay-one {
          display: block;
        }

        .your-findings,
        .ai-findings {
          display: none;
        }
      }

      &.ai-overlay-one {

        .ai-findings {
          svg{
            position: absolute;
            display: block;
            z-index: 99;
          }
        }

        .your-findings {
          display: none;
        }
      }
    }

    #x-ray-two-overlays {

      &.your-overlay-two {

        .clean-overlay-two,
        .ai-circles-overlay {
          display: none;
        }
      }

      &.true-overlay-two {

        .clean-overlay-two {
          display: block;
        }

        .your-findings,
        .ai-findings {
          display: none;
        }
      }

      &.ai-overlay-two {

        .ai-findings,
        .clean-overlay-two {
          display: block;
        }

        .your-findings {
          display: none;
        }
      }
    }

    #x-ray-three-overlays {

      &.your-overlay-three {

        .clean-overlay-three,
        .ai-circles-overlay {
          display: none;
        }
      }

      &.true-overlay-three {

        .clean-overlay-three {
          display: block;
        }

        .your-findings,
        .ai-findings {
          display: none;
        }
      }

      &.ai-overlay-three {

        .ai-findings,
        .clean-overlay-three {
          display: block;
        }

        .your-findings {
          display: none;
        }
      }
    }

    .solutions-slider {

      .solutions-result {
        display: grid !important;
        grid-template-columns: 1fr 430px;
        min-height: calc(100vh - 73px);

        .result-left {
          background: $base-color2;

          .max-container {
            max-width: 1152px;
            width: 100%;
            margin: 0 auto;

            //&.is-result{
            //  max-width: 950px;
            //}
          }

          .img-result-container {
            width: 100%;
            height: auto;
            aspect-ratio: 1.42;
            //padding-bottom: 75%;
            position: relative;
            overflow: hidden;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
          }

          .clean-overlay-one,
          .clean-overlay-two,
          .clean-overlay-three{
            position: absolute;
            //z-index: 1;
            top: -4px;
            left: 0;
            width: 100%;
          }
          

          .ai-circles-overlay {
            position: absolute;
            //z-index: 1;
            top: 4px;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .solution-overlay {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .pathos-desc {
              opacity: 0;
              transition: opacity 650ms ease;

              &.see-patho {
                opacity: 1;
              }
            }
          }
        }

        .result-right {
          position: relative;
          background: linear-gradient(16deg, #252D42 -14.76%, #263465 96.36%);
          padding: 30px 33px 30px;
          overflow: scroll;

          h3 {
            text-align: center;
            color: $base-color1;
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
            margin-bottom: 20px;
          }

          .result-block {
            padding: 15px;
            border-radius: 17px;
            border: 1px solid rgba(144, 144, 144, .2);
            margin-bottom: 40px;
          }

          .findings-block {
            padding: 15px;
            border-radius: 5px;
            position: relative;

            &.no-click{
              margin-bottom: 15px;

              &:after{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                border-bottom: 1px dashed rgba(144, 144, 144, 0.2);
              }
            }

            &.clickable {
              cursor: pointer;

              &:before{
                content: "";
                position: absolute;
                top: 19px;
                right: 20px;
                width: 16px;
                height: 16px;
                background: url("#{$images_dir}eye-off.svg") no-repeat center center;
                background-size: cover;
                transition: all 500ms ease;
              }
            }

            &.current {
              border-radius: 5px;
              background: #4F5A7D;

              &:before{
                background: url("#{$images_dir}eye-on.svg") no-repeat center center;
                background-size: cover;
              }
            }

            h4 {
              color: $base-color1;
              font-size: 20px;
              font-style: normal;
              font-weight: 300;
              line-height: 24px;
              margin-bottom: 18px;
              padding-left: 35px;
              position: relative;

              &.true {

                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  width: 20px;
                  height: 20px;
                  background: url("#{$images_dir}tooth.svg") no-repeat center center;
                  background-size: cover;
                }
              }

              &.your {

                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  width: 20px;
                  height: 20px;
                  background: url("#{$images_dir}your-icon.svg") no-repeat center center;
                  background-size: cover;
                }
              }

              &.ai {

                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  width: 20px;
                  height: 19px;
                  background: url("#{$images_dir}ai-icon-v2.svg") no-repeat center center;
                  background-size: contain;
                }
              }

            }

            .findings {

              li {
                color: $base-color1;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px;
                margin-bottom: 8px;
                padding-left: 35px;
                position: relative;

                &:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 20px;
                  height: 20px;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                &.carry:before {
                  background: $base-color5;
                }

                &.radio:before {
                  background: $base-color4;
                }

                &.calculus:before {
                  background: $base-color6;
                }

                &.margin:before {
                  background: $base-color7;
                }

                &.your:before {
                  background: #23BEE2;
                }
              }
            }
          }
        }
      }
    }

    .custom-arrows-container {
      position: absolute;
      right: 120px;
      bottom: 35px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      .custom-arrow {
        margin: 0 25px;
        cursor: pointer;

        &.slick-disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &.next {

          span:before {
            transform: translateX(-50%) rotate(180deg);
          }
        }

        span {
          display: block;
          color: $base-color1;
          font-size: 22px;
          font-weight: 400;
          padding-bottom: 60px;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 50px;
            height: 50px;
            background: url("#{$images_dir}slider-arrow.svg") no-repeat center center;
            background-size: cover;
          }
        }
      }
    }

    .get-demo-panel {
      position: fixed;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .8);
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.show {
        display: flex;
        animation: opacityAnimation 750ms;
      }

      .demo-container {
        max-width: 750px;
        width: 100%;
        background: #3A4561;
        border-radius: 15px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        padding: 25px 15px 26px;
        position: relative;

        .sup {
          font-size: 18px;
          position: relative;
          top: -8px;
        }

        .close-demo {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 16px;
          height: 16px;
          appearance: none;
          border: none;
          padding: 0;
          background: transparent;
          cursor: pointer;

          &:after,
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 2px;
            background: $base-color1;
          }

          &:before {
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }

        h2 {
          text-align: center;
          color: $base-color1;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 134.523%; /* 32.285px */
          margin-bottom: 20px;
        }

        .hbspt-form {

          .hs-form-field {

            label {
              margin-bottom: 8px !important;
            }
          }

          .legal-consent-container {
            color: $base-color1;
            font-size: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-landscape+1px) {

  #scoreboard{
    overflow: hidden;

    #header{

      .is-mobile-cta{
        display: block;
      }
    }

    .score-grid{
      grid-template-columns: repeat(1,1fr);

      .score-right{
        display: none;
      }
    }

    .leaderboard{

      li{

        &.top-list{

          .top-leaderboard{

            &.has-info.p-info{

              .info-box{
                transform: none;
                left: auto;
                right: 0;
              }
            }
          }
        }
      }
    }

    #solutions-panel{
      position: absolute;
      height: 100%;
      background: linear-gradient(16deg, #252D42 -14.76%, #263465 96.36%);

      &:before{
        display: none;
      }

      .top-solutions{

        .right{
          justify-content: flex-end;

          h2{
            font-size: 16px;
            line-height: 18px;
            margin-right: 10px;
          }

          button{
            min-width: 110px;
            padding: 14px 15px 13px;
          }
        }
      }

      .solutions-slider{

        .solutions-result{
          min-height: auto;
          grid-template-columns: repeat(1,1fr);

          .result-right{
            background: none;

            .result-block{
              margin-bottom: 0;
            }
          }
        }
      }

      .custom-arrows-container{
        right: auto;
        position: relative;
        margin-top: 30px;
      }
    }
  }
}

@media only screen and (max-width: $tablet-portrait+1px) {

  #scoreboard{

    .block{

      &.stats{
        grid-template-columns: repeat(1,1fr);

        .graphs{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          //flex-wrap: wrap;
          gap: 16px;

          .graph{
            height: max-content;
            max-width: 220px;

            &.margin-btm{
              margin-bottom: 0;
            }

            .circle{
              width: 100px;
              height: 100px;

              canvas{
                width: 100px !important;
                height: 100px !important;
              }

              .circle-stats{

                strong{
                  font-size: 28px;
                  line-height: 32px;
                }

                span{
                  font-size: 10px;
                }
              }
            }

            .score-type{
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }
    }

    .score{

      .score-title{
        font-size: 34px;
        line-height: 36px;
        margin-bottom: 16px;
      }

      .your-score{
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 0;
      }

      strong{
        font-size: 70px;
        line-height: 80px;
        margin: 0 14px;
      }

      p{
        font-size: 18px;
        line-height: 22px;
      }
    }

    .leader{
      font-size: 20px;
      line-height: 24px;
      margin: 20px 0;
    }

    #solutions-panel{

      .solutions-slider{

        .solutions-result{

          .result-right{
            padding: 30px 16px;

            h3{
              font-size: 24px;
              line-height: 26px;
            }

            .findings-block{

              h4{
                font-size: 18px;
              }

              .findings{

                li{
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .get-demo-panel{

        .demo-container{

          h2{
            font-size: 18px;
            margin-bottom: 10px;
          }

          .hbspt-form{

            .hs-form-field{
              margin-bottom: 10px;

              label{
                font-size: 14px;
                margin-bottom: 2px !important;
              }

              input,
              select{
                height: 25px;
              }
            }

            .hs-country,
            .imaging_software_new{

              &:before{
                top: 30px;
              }
            }

            .actions{

              .hs-button{
                margin-top: 0;
                font-size: 14px;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-landscape+1px) {

  #scoreboard{

    .leaderboard{

      &.has-height{
        max-height: 400px;
      }

      li{
        font-size: 11px;
        padding: 8px 4px;

        &.top-list{

          .top-leaderboard{

            &.has-info{

              strong{

                &:after{
                  display: none;
                }
              }

              .info-box{
                display: none;
              }
            }
          }
        }
      }
    }

    #solutions-panel{

      .top-solutions{
        grid-template-columns: 60px 1fr;

        .left{

          .close-solutions{

            span{
              font-size: 0;

              &:before{
                left: 6px;
              }
            }
          }
        }
      }
    }
  }
}

